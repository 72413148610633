<template>
  <div class="container">
   
    <Header></Header>


    <div class="ny-main">
     
        <h3 class="ny-title text-center">CONTACT  US</h3>

        <div class="ny-con">
          <el-form ref="form" :rules="rules" :model="form" class="cont-form">
            <el-form-item label="Ask our sales for order:">
              <div class="form-txt">Please fill our the form below and one of our staff will contact you via email or phone.</div>
            </el-form-item>
            <el-row :gutter="40">
                <el-col :span="12">
                  <el-form-item label="Name">
                    <el-input v-model="form.name" placeholder="Please enter your name." ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Email">
                    <el-input v-model="form.email" placeholder="Example: peter.parker@zylker.com"></el-input>
                  </el-form-item>
                </el-col>
            </el-row>
            
            <el-row :gutter="40">
                <el-col :span="12">
                  <el-form-item label="Phone">
                    <el-input v-model="form.telephone" placeholder="Example: +8613378643944"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Company Name">
                    <el-input v-model="form.company_name" placeholder="Example: STARMAX INT'L GROUP CO., LIMITED"></el-input>
                  </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="40">
                <el-col :span="12">
                  <el-form-item label="Address">
                    <el-input v-model="form.address" placeholder="Example: Suzy Queue 4455 Landing Lange, APT 4 Louisville, KY 40018-1234"></el-input>
                  </el-form-item>
                  <el-form-item label="Need to schedule a free phone call for you?Do you need to call back?">
                    <div class="cont-radio">
                      <el-radio-group v-model="form.is_call_back">
                        <p><el-radio label="Yes"></el-radio></p>
                        <p><el-radio label="No"></el-radio></p>
                      </el-radio-group>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Information" placeholder="Please tell us what you want to know.">
                    <el-input type="textarea" v-model="form.information"></el-input>
                  </el-form-item>
                </el-col>
            </el-row>

            <el-form-item class="text-center">
              <el-button type="primary" :disabled="isDisabled" @click="onSubmit()" class="contact-btn">Submit</el-button>
            </el-form-item>
          </el-form>
        </div>
     
    </div>
    <!-- main end  -->
     <Footer></Footer>
  </div>  
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import { add } from '@/api/contact'
export default {
  components: {
    Header,
    Footer,
  
  },
  data() {
    return {
	  isDisabled: false,
      form: {
        name: '',
        email: '',
        telephone: '',
        company_name: '',
        address: '',
        information: '',
        is_call_back: 'No',
      },
      rules: {
	    name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
	  },
    };
  },
 
 
  methods: {
    onSubmit(){
          this.isDisabled=true
          add(this.form).then(res => {
			this.isDisabled=false
			if(res.data.code==200){
			  res.data.msg='Submitted successfully!</br>Our sales will contact you within 48 hours.'
			}
			this.$alert(res.data.msg, {
			  confirmButtonText: 'OK',
			  center:true,
			  dangerouslyUseHTMLString: true
			}).then(()=>{
		      if(res.data.code==200){
                this.$router.push('/home')
			  }
            });
			
          }).catch(() => {
            this.isDisabled=false
          })
	}
  },
}
</script>

<style scoped >

.ny-main {
  overflow: hidden;
  padding: 2% 8.3vw;
}

.ny-title {
  color: #0a3c5b;
  line-height: 1;
  font-size: 3.75vw;
  font-family: DINCound;
  font-weight: normal;
}

.ny-con {
  width: 100%;
  box-sizing: border-box;
  padding: 1.5% 5%;

}

.form-txt{
  position: relative;
  font-size: 1.6667vw;
  display: inline-block;
  width: 100%;
  color: #0a3c5b;
  font-family: DINCound;
}

.cont-form /deep/ .el-form-item{
  margin-bottom: 10px;
}

.cont-form /deep/ .el-form-item__label {
width:100%;
text-align: left;
  padding: 5px 12px 5px 0;
}

.cont-form /deep/ .el-form-item__label{
  font-size:1.25vw;
  color:#000;
}
.cont-form /deep/ .el-input__inner{
  height: 40px;
  line-height: 40px;
  
}
.cont-form /deep/ .el-textarea__inner{
  height: 130px;
}

.cont-form /deep/ .el-input__inner,
.cont-form /deep/ .el-textarea__inner{
  font-size: 0.8vw;
  color: #0a3c5b;
  border-color: #0a3c5b;
}
.cont-form .el-input,.cont-form .el-textarea{ width:85%;}
.cont-radio{
  position: relative;
  display: inline-block;
  width: 100%;
}

.cont-radio p{
  margin-bottom: 20px;
}

.cont-radio /deep/ .el-radio__label{
  font-size: 18px;
  color: #000;
}

.cont-radio /deep/ .el-radio__inner{
  border: 1px solid #0a3c5b;
  width: 28px;
  height: 28px;
}

.cont-radio /deep/ .el-radio__input.is-checked .el-radio__inner{
  background-color: #0a3c5b;
}

.cont-radio /deep/ .el-radio__inner::after{
  width: 28px;
  height: 28px;
  background: #0a3c5b url("https://api.starmaxintl.com/storage/file/20221004/6cdc89227411b77a432c3aa1d7b499b3110d1d40.png") center no-repeat;
}


.cont-form /deep/ .el-button--primary{
  background-color: #0a3c5b;
  border-color: #0a3c5b;
  border-radius: 0;
  width: 290px;
  height: 70px;
  line-height: 46px;
  font-size: 24px;
  font-family: DINCound;
  margin-top: 20px;
}

.cont-form /deep/ .el-button--primary:hover{
  opacity: 0.9;
}



</style>
